import $ from "jquery";
import { createQueryString } from "./index.js";

const handle401Error = (error) => {
  // we aren't authenticated, reload entire app, logging out of SPA
  window.location.reload();
};

const api = {
  /*
   @param {Promise}
   */
  getRetailers: function () {
    return $.ajax({
      url: "/api/v1/db/retailers",
      type: "GET",
      statusCode: {
        401: handle401Error,
      },
    });
  },

  getManufacturerRetailers: function (manufacturerId) {
    return $.ajax({
      url: "/api/v1/db/retailers/" + manufacturerId,
      type: "GET",
      statusCode: {
        401: handle401Error,
      },
    });
  },

  getUtility: function (primaryUtilId) {
    return $.ajax({
      url: `/api/v1/db/utility/${primaryUtilId}`,
      type: "GET",
      statusCode: {
        401: handle401Error,
      },
    });
  },

  getUtilities: function () {
    return $.ajax({
      url: `/api/v1/db/utilities`,
      type: "GET",
      statusCode: {
        401: handle401Error,
      },
    });
  },

  getHomeUpgrade: function (id) {
    return $.ajax({
      url: `/api/v1/db/homeupgrade/${id}`,
      type: "GET",
      statusCode: {
        401: handle401Error,
      },
    });
  },

  getHomeIncentive: function (primaryUtilId, brandID, certDate) {
    var JSONObject = {
      utility_id: primaryUtilId,
      brand_id: brandID,
      cert_date: certDate,
    };
    return $.ajax({
      url: "/api/v1/db/homeincentive",
      type: "GET",
      data: JSONObject,
      statusCode: {
        401: handle401Error,
      },
    });
  },

  getIsUniqueSerial: function (ManufacturerID, SerialNumber) {
    var JSONObject = {
      manufacturer_id: ManufacturerID,
      serial_number: SerialNumber,
    };
    return $.ajax({
      url: "/api/v1/db/isuniqueserial",
      type: "GET",
      data: JSONObject,
      statusCode: {
        401: handle401Error,
      },
    });
  },

  getHomeType: function () {
    return $.ajax({
      url: "/api/v1/db/hometype",
      type: "GET",
      statusCode: {
        401: handle401Error,
      },
    });
  },

  getHeatingSystem: function () {
    return $.ajax({
      url: "/api/v1/db/heatingsystem",
      type: "GET",
      statusCode: {
        401: handle401Error,
      },
    });
  },

  getEnergySource: function () {
    return $.ajax({
      url: "/api/v1/db/energysource",
      type: "GET",
      statusCode: {
        401: handle401Error,
      },
    });
  },

  getHomeStatus: function () {
    return $.ajax({
      url: "/api/v1/db/homestatus",
      type: "GET",
      statusCode: {
        401: handle401Error,
      },
    });
  },

  getManufacturers: function () {
    return $.ajax({
      url: "/api/v1/db/manufacturers",
      type: "GET",
      statusCode: {
        401: handle401Error,
      },
    });
  },

  getManufacturerById: function (id) {
    return $.ajax({
      url: `/api/v1/db/manufacturers/${id}`,
      type: "GET",
      statusCode: {
        401: handle401Error,
      },
    });
  },

  getPath: function () {
    return $.ajax({
      url: "/api/v1/db/path",
      type: "GET",
      statusCode: {
        401: handle401Error,
      },
    });
  },

  getManufacturerPath: function (manufacturerId) {
    return $.ajax({
      url: "/api/v1/db/path/" + manufacturerId,
      type: "GET",
      statusCode: {
        401: handle401Error,
      },
    });
  },

  getFilteredPath: function ({
    manufacturerId,
    heatingSystemId,
    brandId,
    homeTypeId,
  }) {
    //var JSONObject= {"manufacturer_id":manufacturerId, "heating_system_id":heatingSystemId, "brand_id":brandId };
    var JSONObject = {};
    if (!!manufacturerId || manufacturerId === "") {
      JSONObject.manufacturer_id = manufacturerId;
    }
    if (!!heatingSystemId || heatingSystemId === "") {
      JSONObject.heating_system_id = heatingSystemId;
    }
    if (!!brandId || brandId === "") {
      JSONObject.brand_id = brandId;
    }
    if (homeTypeId != null) {
      JSONObject.home_type_id = homeTypeId;
    }

    return $.ajax({
      url: "/api/v1/db/getfilteredpath",
      type: "GET",
      data: JSONObject,
      statusCode: {
        401: handle401Error,
      },
    });
  },

  getStates: function () {
    return $.ajax({
      url: "/api/v1/db/states",
      type: "GET",
      statusCode: {
        401: handle401Error,
      },
    });
  },

  getBrand: function () {
    return $.ajax({
      url: "/api/v1/db/brand",
      type: "GET",
      statusCode: {
        401: handle401Error,
      },
    });
  },

  getHomes: function (searchData) {
    return $.ajax({
      url: "/api/v1/db/homesearch",
      data: JSON.stringify(searchData),
      type: "POST",
      contentType: "application/json",
      statusCode: {
        401: handle401Error,
      },
    });
  },

  getHome: function (homeId) {
    return $.ajax({
      url: "/api/v1/db/homes/" + homeId,
      type: "GET",
      statusCode: {
        401: handle401Error,
      },
    });
  },

  updateHome: function (homeId, homeData, geocode) {
    geocode = geocode || false;
    let url = `/api/v1/db/homes/${homeId}`;
    if (geocode) {
      const queryString = createQueryString({ geocode: true });
      url = `${url}?${queryString}`;
    }
    return $.ajax({
      url: url,
      data: JSON.stringify(homeData),
      type: "PUT",
      contentType: "application/json",
      statusCode: {
        401: handle401Error,
      },
    });
  },

  addHome: function (homeData) {
    return $.ajax({
      url: "/api/v1/db/homes",
      data: JSON.stringify(homeData),
      type: "POST",
      contentType: "application/json",
      statusCode: {
        401: handle401Error,
      },
    });
  },

  deleteHome: function (homeId) {
    return $.ajax({
      url: "/api/v1/db/homes/" + homeId,
      type: "DELETE",
      statusCode: {
        401: handle401Error,
      },
    });
  },
};

export default api;
