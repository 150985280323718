import "./home.css";
import Component from "../components/component.react.js";
import React from "react";
import PropTypes from "prop-types";
import Formsy from "formsy-react";
import { Input, Select, Row } from "formsy-react-components";
import { Alert, Col, Grid } from "react-bootstrap";
import { connect } from "react-redux";
import * as HomeActions from "../redux/actions/home.js";
import { Navigate } from "react-router-dom";

function getGoogleMapsButtonForHome(home) {
  let link;
  let buttonLabel;
  if (home.lat && home.lng) {
    link = `https://maps.google.com/maps?q=${home.lat},${home.lng}&ll=${home.lat},${home.lng}&z=7`;
    buttonLabel = "Map (from previously set lat lng)";
  } else {
    let addrString;
    if (home.SiteAddress) {
      addrString = home.SiteAddress;
    }
    if (home.SiteCity) {
      if (addrString) {
        addrString += ",+" + home.SiteCity;
      } else {
        addrString = home.SiteCity;
      }
    }
    if (home.SiteState) {
      if (addrString) {
        addrString += ",+" + home.SiteState;
      } else {
        addrString = home.SiteState;
      }
    }
    if (home.SiteZip) {
      if (addrString) {
        addrString += ",+" + home.SiteZip;
      } else {
        addrString = home.SiteZip;
      }
    }
    if (addrString) {
      link = `https://maps.google.com/maps?q=${addrString}&z=7`;
      buttonLabel = "Map (from siting address)";
    }
  }

  if (link) {
    return (
      <a className="btn btn-primary" href={link} target="_blank">
        {buttonLabel}
      </a>
    );
  } else {
    return <a className="btn btn-primary disabled">Map</a>;
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log("state", state);
  // console.log("ownProps", ownProps);
  // // ManufacturerID is set in 4 different ways
  // let ManufacturerID;
  // // 1 - logged-in as Manufacturer user
  // if (activeUser.isManufacturer) {
  //   ManufacturerID = activeUser.companyId;
  // }
  // // 2 - manufacturerId passed in as prop - not used anymore
  // else if (this.props.manufacturerId) {
  //   ManufacturerID = this.props.manufacturerId;
  // }
  // // 3 - if this isn't a new home, ManufacturerID is later set from this.props.home.ManufacturerID in componentWillReceiveProps
  // // 4 - the state's ManufacturerID is changed whenever a different manufacturer is selected in the dropdown
  return {
    ...state.home.toObject(),
    homeId: ownProps.homeId,
  };
};

class Home extends Component {
  constructor(props, context) {
    super(props, context);

    // so these functions have access to the proper 'this'
    this.submitForm = this.submitForm.bind(this);
    this.cancelAdd = this.cancelAdd.bind(this);
    this.reprintCert = this.reprintCert.bind(this);
    this.deleteHome = this.deleteHome.bind(this);
    this.changeProp = this.changeProp.bind(this);
    this.isEmpty = this.isEmpty.bind(this);
    this.serialNumberChanged = this.serialNumberChanged.bind(this);
    // this.geocodeAddress = this.geocodeAddress.bind(this);

    this.getManufacturerSpecificValues =
      this.getManufacturerSpecificValues.bind(this);

    const activeUser = Component.getActiveUserRecord();
    // console.log("activeUser", activeUser);

    const isNewHome = !this.props.homeId;
    // console.log("homeId", this.props.homeId);
    // console.log("isNewHome", isNewHome);

    // ManufacturerID is set in 4 different ways
    let ManufacturerID;
    // 1 - logged-in as Manufacturer user
    if (activeUser?.isManufacturer) {
      // console.log("manufacturer logged in");
      ManufacturerID = activeUser.companyId;
    }
    // 2 - manufacturerId passed in as prop - not used anymore
    else if (this.props.manufacturerId) {
      // console.log("non-manufacturer logged in");
      ManufacturerID = this.props.manufacturerId;
    }
    // 3 - if this isn't a new home, ManufacturerID is later set from this.props.home.ManufacturerID in componentWillReceiveProps
    // 4 - the state's ManufacturerID is changed whenever a different manufacturer is selected in the dropdown

    this.state = {
      pageTitle: props.defaultTitle,
      ManufacturerID: ManufacturerID,
      HeatingSystemID: this.props.home.HeatingSystemID,
      BrandID: this.props.home.BrandID,
      RetailerID: this.props.home.RetailerID,
      isNewHome: isNewHome,
      submitting: false,
      errorMessages: "",
      showAlert: false,
      activeUser: activeUser,
      manufacturer: this.props.manufacturer,
      dirtySerialNumber: false,
      redirectTo: null,
    };

    // console.log("this props", this.props);

    this.getManufacturerSpecificValues();

    // Populate the dropdowns from the store
    this.props.dispatch(HomeActions.getHomeType());
    this.props.dispatch(HomeActions.getHeatingSystem());
    this.props.dispatch(HomeActions.getEnergySource());
    this.props.dispatch(HomeActions.getHomeStatus());
    this.props.dispatch(HomeActions.getBrand());
    this.props.dispatch(HomeActions.getStates());
    if (activeUser?.isAdmin) {
      this.props.dispatch(HomeActions.getUtilities());
    }
    if (isNewHome || activeUser.isAdmin) {
      this.props.dispatch(HomeActions.getManufacturers());
    } else {
      this.props.dispatch(
        HomeActions.getManufacturerById(this.state.ManufacturerID)
      );
    }
    this.formRef = React.createRef();
  }

  componentDidMount() {
    // console.log("Home componentDidMount");
    // If this is an edit then load the home data from the store
    if (!this.state.isNewHome) {
      const getHomeActionCreator = HomeActions.getHome(this.props?.homeId);
      const getHomePromise = getHomeActionCreator.payload;
      this.props.dispatch(getHomeActionCreator);

      getHomePromise
        // once we grab the Home object, also get its Manufacturer
        .then((data) =>
          this.props.dispatch(
            HomeActions.getManufacturerById(data.ManufacturerID)
          )
        );

      // Set the page title depending on a new home or edit
      this.setState({ pageTitle: "HOME AND SITING INFORMATION" });
    } else {
      if (this.state.activeUser?.isManufacturer) {
        this.props.dispatch(
          HomeActions.getManufacturerById(this.state.activeUser.companyId)
        );
      } else {
        // new home, not bound to logged-in manufacturer
        this.props.dispatch(HomeActions.getManufacturers());
      }
    }
  }

  componentWillReceiveProps(newProps) {
    // console.log("new props", newProps);
    if (
      newProps.manufacturer.ManufacturerID !==
      this.props.manufacturer.ManufacturerID
    ) {
      this.setState({
        manufacturer: newProps.manufacturer,
        ManufacturerID: newProps.manufacturer.ManufacturerID,
      });
    }
    if (newProps.home.RetailerID !== this.props.home.RetailerID) {
      if (!this.state.RetailerID) {
        this.setState({ RetailerID: newProps.home.RetailerID });
      }
    }
    if (newProps.home.HeatingSystemID !== this.props.home.HeatingSystemID) {
      if (!this.state.HeatingSystemID) {
        this.setState({ HeatingSystemID: newProps.home.HeatingSystemID });
      }
    }
    if (newProps.home.BrandID !== this.props.home.BrandID) {
      if (!this.state.BrandID) {
        this.setState({ BrandID: newProps.home.BrandID });
      }
    }
    if (newProps.home.HomeTypeID !== this.props.home.HomeTypeID) {
      if (!this.state.HomeTypeID) {
        this.setState({ HomeTypeID: newProps.home.HomeTypeID });
      }
    }
  }

  // called whenever state or props change, but not on intial load
  componentDidUpdate(prevProps, prevState) {
    // console.log("Home componentDidUpdate prevState", prevState);
    if (this.state.ManufacturerID) {
      if (
        this.state.HeatingSystemID &&
        this.state.BrandID &&
        this.state.HomeTypeID
      ) {
        // If any of these values change then get a new filtered path
        if (
          this.state.ManufacturerID !== prevState.ManufacturerID ||
          this.state.HeatingSystemID !== prevState.HeatingSystemID ||
          this.state.BrandID !== prevState.BrandID ||
          this.state.HomeTypeID !== prevState.HomeTypeID
        ) {
          this.props.dispatch(
            HomeActions.getFilteredPath({
              manufacturerId: this.state.ManufacturerID,
              heatingSystemId: this.state.HeatingSystemID,
              brandId: this.state.BrandID,
              homeTypeId: this.state.HomeTypeID,
            })
          );
        }
      }

      // When ManufacturerID changes, recalculate the retailers and paths
      if (this.state.ManufacturerID !== prevState.ManufacturerID) {
        this.props.dispatch(
          HomeActions.getManufacturerRetailers(this.state.ManufacturerID)
        );
        this.props.dispatch(
          HomeActions.getManufacturerPath(this.state.ManufacturerID)
        );
        // set the manufacturer prop, so we can reference it's SNLength limit
        this.props.dispatch(
          HomeActions.getManufacturerById(this.state.ManufacturerID)
        );
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(HomeActions.resetHomeStore());
  }

  getManufacturerSpecificValues() {
    // const manufacturerId = this.state.manufacturerId;
    // this.setState({manufacturerId: manufacturerId});

    if (this.state.ManufacturerID) {
      this.props.dispatch(
        HomeActions.getManufacturerRetailers(this.state.ManufacturerID)
      );
      this.props.dispatch(
        HomeActions.getManufacturerPath(this.state.ManufacturerID)
      );
    }
  }

  calcGlazing(data) {
    let skylight = parseFloat(data.SkylightSqFt);
    let glazing = parseFloat(data.WindowSqFt);
    let glass = parseFloat(data.GlassDoorSqFt);
    let floorarea = parseFloat(data.HomeSqFt);
    let maxglazing = parseFloat(this.props.path.MaxGlazing);

    if (skylight > 40) {
      let x = ((skylight - 40) * 2 + glazing + glass) / floorarea;
      x = Math.round(x * 10000) / 10000; //round number to five decimal places
      let maximum = parseFloat(0.15);
      if (maxglazing < 0.15) {
        maximum = maxglazing;
      }
      if (x > maximum) {
        this.addErrorMessage("The skylight area exceeds maximum allowed");
        return false;
      }
    } else {
      let x = (glazing + glass) / floorarea;
      x = Math.round(x * 10000) / 10000; //round number to five decimal places
      if (x > maxglazing) {
        let maxpercent = maxglazing * 100;
        this.addErrorMessage(
          "The glazing for this home is not in the allowable range. The maximum glazing allowed for this path is " +
            maxpercent +
            "%"
        );
        return false;
      }
    }

    return true;
  }

  isUniqueSerial(data, ManufacturerID, SerialNumber, isNewHome) {
    if (isNewHome || this.state.dirtySerialNumber) {
      this.props.dispatch(
        HomeActions.getIsUniqueSerial(
          ManufacturerID,
          SerialNumber,
          (response) => {
            this.handleValidation(data, response);
          }
        )
      );
    } else {
      this.handleValidation(data, true);
    }
  }

  addErrorMessage(message) {
    let existingMessages = this.state.errorMessages;
    let finalMessages = "";
    if (existingMessages !== "") {
      finalMessages = `${existingMessages}, ${message}`;
    } else {
      finalMessages = message;
    }
    this.setState({ errorMessages: finalMessages });
  }

  clearErrorMessage() {
    this.setState({ errorMessages: "" });
  }

  submitForm(data) {
    this.closeAlert();
    this.clearErrorMessage();
    this.setState({ submitting: true });
    this.isUniqueSerial(
      data,
      data.ManufacturerID,
      data.SerialNumber,
      this.state.isNewHome
    );
  }

  handleValidation(data, isUnique) {
    // console.log("handle validation data", data);
    if (
      data.SerialNumber &&
      data.SerialNumber.length !== this.props.manufacturer.SNLength
    ) {
      this.addErrorMessage(
        `Serial Number must be ${this.props.manufacturer.SNLength} characters long.`
      );
      this.openAlert();
      this.setState({ submitting: false });
      return;
    }
    if (isUnique && this.calcGlazing(data)) {
      if (this.state.isNewHome) {
        data.CreateBy = this.state.activeUser.userName; // TODO: do this on server using auth
        this.props.dispatch(
          HomeActions.addHome(data, (homeId) => {
            this.setState({ redirectTo: `/certificate/${homeId}` });
            // this.props?.history.pushState({}, `/certificate/${homeId}`);
            // window.location.href = `/certificate/${homeId}`;
          })
        );
      } else {
        data.LastUpdateBy = this.state.activeUser.userName; // TODO: do this on server using auth
        if (data.Coordinates) {
          if (data.Coordinates.includes(",")) {
            let latLngArray;
            if (data.Coordinates.includes(", ")) {
              latLngArray = data.Coordinates.split(", ");
            } else {
              latLngArray = data.Coordinates.split(",");
            }
            data.lat = latLngArray[0];
            data.lng = latLngArray[1];
          }
        }
        delete data.Coordinates; // Coordinates is not an actual Postgres column, so don't submit it
        // geocode updated home if any part of the address is edited, and the Coordinates aren't  edited
        const geocode = this.props.addressEdited && !this.props.latLngEdited;
        this.props.dispatch(
          HomeActions.updateHome(this.props.homeId, data, geocode, () => {
            this.setState({ redirectTo: `/certificate/${this.props.homeId}` });
            // this.props?.history.pushState(
            //   {},
            //   `/certificate/${this.props.homeId}`
            // );
            // window.location.href = `/certificate/${this.props.homeId}`;
            this.setState({ submitting: false });
          })
        );
      }
    } else {
      if (!isUnique) {
        this.addErrorMessage("That Serial Number is in use");
      }
      this.openAlert();
      this.setState({ submitting: false });
    }
  }

  openAlert() {
    this.setState({ showAlert: true });
  }

  closeAlert() {
    this.setState({ showAlert: false });
  }

  cancelAdd(data) {
    // this.props?.history?.push("/");
    // window.location.href = "/";
    this.setState({ redirectTo: "/" });
  }

  reprintCert(data) {
    // this.props?.history.pushState({}, `/certificate/${this.props.homeId}`);
    // window.location.href = `/certificate/${this.props.homeId}`;
    // console.log("reprintCert");
    this.setState({ redirectTo: `/certificate/${this.props.homeId}` });
  }

  deleteHome(data) {
    let reallyDelete = window.confirm(
      "Are you sure you want to Delete this home?"
    );
    if (reallyDelete === true) {
      this.props.dispatch(HomeActions.deleteHome(this.props.homeId));
      // this.props?.history.pushState("/");
      // window.location.href = "/";
      this.setState({ redirectTo: "/" });
    }
  }

  // Set an internal state value when the form component is changed
  // this is used for form properties that trigger other components to be
  // updated when they are modified
  changeProp(name, value) {
    // console.log("changeProp name value", name, value);
    let newState = {};
    newState[name] = value;
    this.setState(newState);
  }

  // // This works, but we decided to perform this on the server instead
  // geocodeAddress() {
  //   const home = this.props.home;
  //   const { SiteAddress, SiteCity, SiteState, SiteZip } = this.refs.form.getCurrentValues();
  //   const latInput = this.refs.lat;
  //   const longInput = this.refs.long;
  //   if (!SiteAddress) {
  //     this.addErrorMessage('Siting Address is required to geocode');
  //     this.openAlert();
  //     return;
  //   } else {
  //     this.clearErrorMessage();
  //     this.closeAlert();
  //   }
  //   geocodeUsingTexasAandMGeoservices(SiteAddress, SiteCity, SiteState, SiteZip)
  //     .then((json) => {
  //       if (json.FeatureMatchingResultType === "Unmatchable") {
  //         this.addErrorMessage('Failed to geocode address');
  //         return;
  //       }
  //       const numMatches = parseInt(json.FeatureMatchingResultCount);
  //       if (numMatches > 0) {
  //         // use the first match
  //         const geocodeMatch = json.OutputGeocodes[0].OutputGeocode;
  //         if (parseInt(geocodeMatch.MatchScore) >= 80) {
  //           latInput.setValue(geocodeMatch.Latitude);
  //           longInput.setValue(geocodeMatch.Longitude);
  //         }
  //       } else {
  //         this.addErrorMessage('Failed to geocode address');
  //       }
  //     });
  // }

  // Only make the field required if we get values back from the dropdown
  isEmpty(itemList) {
    let isEmpty = false;
    if (itemList.count() > 0) {
      isEmpty = true;
    }
    return isEmpty;
  }

  // Called when serial number is modified will track is changed
  // for isUnique tests on submit
  serialNumberChanged() {
    this.setState({ dirtySerialNumber: true });
  }

  render() {
    const {
      home,
      manufacturer,
      manufacturers,
      utilities,
      homeTypes,
      manufacturerRetailers,
      heatingSystem,
      energySource,
      brands,
      filteredPath,
      homeStatus,
      states,
    } = this.props;

    // Check if we should redirect
    if (this.state.redirectTo) {
      return <Navigate to={this.state.redirectTo} />;
    }

    let SectionsOptions = [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6+" },
    ];

    SectionsOptions.unshift({ value: "", label: "Please select..." });

    let SharedProps = {
      layout: "horizontal",
      validatePristine: false,
    };

    // const HomeTypeSelectList = this.getSelectItemList(homeTypes);

    // let manufacturerFullName = null;
    // if (manufacturer) {
    //   manufacturerFullName = manufacturer.label;
    // }

    // let retailerRequired = (manufacturerRetailers.count() < 0);

    const homeManufacturerID = "ManufacturerID",
      homeSerialNumber = "SerialNumber",
      homeRetailerID = "RetailerID",
      homeTypeID = "HomeTypeID",
      homeModelNumber = "ModelNumber",
      homeSections = "Sections",
      homeHeatingSystemID = "HeatingSystemID",
      homeEnergySourceID = "EnergySourceID",
      homeBrandID = "BrandID",
      homePathID = "PathID",
      homeStatusID = "HomeStatusID",
      homeSqFt = "HomeSqFt",
      homeGlassDoorSqFt = "GlassDoorSqFt",
      homeWindowSqFt = "WindowSqFt",
      homeSkylightSqFt = "SkylightSqFt",
      homeSiteAddress = "SiteAddress",
      homeSiteCity = "SiteCity",
      homeSiteState = "SiteState",
      homeSiteZip = "SiteZip",
      homeSitePhone = "SitePhone",
      homeSiteEmail = "SiteEmail",
      homeOwnerFN = "OwnerFN",
      homeOwnerLN = "OwnerLN",
      homeElectricUtilityID = "ElecUtilityID",
      homeGasUtilityID = "GasUtilityId";

    // TODO need to add a conditional that makes items not required if the Select is empty

    // console.log("home component state", this.state);

    let manufacturerOptions;
    if (!this.state.isNewHome || this.state.activeUser?.isManufacturer) {
      manufacturerOptions = this.getSelectItemList([
        {
          value: this.state.ManufacturerID,
          label: this.state.manufacturer.label,
        },
      ]);
    } else {
      manufacturerOptions = this.getSelectItemListFromRecords(manufacturers);
    }

    // console.log("manufacturerOptions", manufacturerOptions);

    return (
      <div className="row">
        <div className="page-header">
          <h1>{this.state.pageTitle}</h1>
        </div>
        <div className="well">
          <Formsy
            className="form-horizontal"
            onValidSubmit={this.submitForm}
            ref={this.formRef}
          >
            <fieldset>
              <legend>Home Information</legend>
              <Select
                {...SharedProps}
                name={homeManufacturerID}
                label="Manufacturer"
                labelClassName="control-label"
                className="form-control"
                options={manufacturerOptions}
                value={this.state.ManufacturerID}
                disabled={
                  this.state.activeUser?.isManufacturer || !this.state.isNewHome
                }
                // onChange={this.changeProp}
                changeCallback={(homeManufacturerID, value) =>
                  this.changeProp(homeManufacturerID, value)
                }
                required
              />
              <Input
                {...SharedProps}
                name={homeSerialNumber}
                id="serialNumber"
                value={home[homeSerialNumber]}
                label="Serial Number"
                labelClassName="control-label"
                className="form-control"
                type="text"
                placeholder="Serial Number is required."
                maxLength={manufacturer.SNLength || 20}
                onChange={this.serialNumberChanged}
                required
              />
              <Select
                {...SharedProps}
                name={homeRetailerID}
                label="Retailer"
                labelClassName="control-label"
                className="form-control"
                options={this.getSelectItemList(manufacturerRetailers)}
                value={this.state.RetailerID}
                // onChange={this.changeProp}
                changeCallback={(homeRetailerID, value) =>
                  this.changeProp(homeRetailerID, value)
                }
                required
              />
              <Select
                {...SharedProps}
                name={homeTypeID}
                label="Home Type"
                labelClassName="control-label"
                className="form-control"
                help=""
                options={this.getSelectItemList(homeTypes)}
                value={this.state.HomeTypeID}
                // onChange={this.changeProp}
                changeCallback={(homeTypeID, value) =>
                  this.changeProp(homeTypeID, value)
                }
                required
              />
              <Input
                {...SharedProps}
                name={homeModelNumber}
                id="modelNumber"
                value={home[homeModelNumber]}
                label="Model Number"
                labelClassName="control-label"
                className="form-control"
                type="text"
                placeholder="Model Number is required."
                maxLength="20"
                required
              />
              <Select
                {...SharedProps}
                name={homeSections}
                label="Number Of Sections"
                labelClassName="control-label"
                className="form-control"
                options={SectionsOptions}
                value={home[homeSections]}
                required
              />
              <Select
                {...SharedProps}
                name={homeHeatingSystemID}
                label="Primary Heating System"
                labelClassName="control-label"
                className="form-control"
                options={this.getSelectItemList(heatingSystem)}
                value={this.state.HeatingSystemID}
                // onChange={this.changeProp}
                changeCallback={(homeHeatingSystemID, value) =>
                  this.changeProp(homeHeatingSystemID, value)
                }
                required
              />
              <Select
                {...SharedProps}
                name={homeEnergySourceID}
                label="Water Heater Type"
                labelClassName="control-label"
                className="form-control"
                options={this.getSelectItemList(energySource)}
                value={home[homeEnergySourceID]}
                required
              />
              <Select
                {...SharedProps}
                name={homeBrandID}
                label="Certification Brand"
                labelClassName="control-label"
                className="form-control"
                options={this.getSelectItemList(brands)}
                value={this.state.BrandID}
                // onChange={this.changeProp}
                changeCallback={(homeBrandID, value) =>
                  this.changeProp(homeBrandID, value)
                }
                required
              />
              <Select
                {...SharedProps}
                name={homePathID}
                label="Path Description"
                labelClassName="control-label"
                className="form-control"
                options={this.getSelectItemList(filteredPath)}
                value={home[homePathID]}
                required
              />
              <Select
                {...SharedProps}
                name={homeStatusID}
                label="Home Status"
                labelClassName="control-label"
                className="form-control"
                options={this.getSelectItemList(homeStatus)}
                value={home[homeStatusID]}
                required
              />
              <Input
                {...SharedProps}
                name={homeSqFt}
                id="floorArea"
                value={home[homeSqFt]}
                label="Floor Area (Sq. Ft.)"
                labelClassName="control-label"
                className="form-control"
                type="text"
                placeholder="Floor Area is required."
                validations="isNumeric"
                required
              />
              <Input
                {...SharedProps}
                name={homeGlassDoorSqFt}
                id="glassDoorArea"
                value={home[homeGlassDoorSqFt]}
                label="Glass Door Area (Sq. Ft.)"
                labelClassName="control-label"
                className="form-control"
                type="text"
                placeholder="Glass Door Area is required."
                validations="isNumeric"
                required
              />
              <Input
                {...SharedProps}
                name={homeWindowSqFt}
                id="glazingArea"
                value={home[homeWindowSqFt]}
                label="Glazing Area (Sq. Ft.)"
                labelClassName="control-label"
                className="form-control"
                type="text"
                placeholder="Glazing Area is required."
                validations="isNumeric"
                required
              />
              <Input
                {...SharedProps}
                name={homeSkylightSqFt}
                id="skylightArea"
                value={home[homeSkylightSqFt]}
                label="Skylight Area (Sq. Ft.)"
                labelClassName="control-label"
                className="form-control"
                type="text"
                placeholder="Skylight Area is required."
                validations="isNumeric"
                required
              />
            </fieldset>
            <fieldset>
              <legend>Siting Information</legend>
              <Input
                {...SharedProps}
                name={homeSiteAddress}
                id="sitingAddress"
                value={home[homeSiteAddress]}
                label="Siting Address"
                labelClassName="control-label"
                className="form-control"
                type="text"
                maxLength="50"
                onChange={() =>
                  this.props.dispatch(HomeActions.addressEdited())
                }
              />
              <Input
                {...SharedProps}
                name={homeSiteCity}
                id="city"
                value={home[homeSiteCity]}
                label="City"
                labelClassName="control-label"
                className="form-control"
                type="text"
                maxLength="25"
                onChange={() =>
                  this.props.dispatch(HomeActions.addressEdited())
                }
              />
              <Select
                {...SharedProps}
                name={homeSiteState}
                label="State"
                labelClassName="control-label"
                className="form-control"
                options={this.getSelectItemList(states)}
                value={home[homeSiteState]}
                onChange={() =>
                  this.props.dispatch(HomeActions.addressEdited())
                }
              />
              <Input
                {...SharedProps}
                name={homeSiteZip}
                id="zipCode"
                value={home[homeSiteZip]}
                label="Zip Code"
                labelClassName="control-label"
                className="form-control"
                type="text"
                maxLength="10"
                onChange={() =>
                  this.props.dispatch(HomeActions.addressEdited())
                }
              />
              {!this.state.isNewHome &&
                this.props.addressEdited &&
                !this.props.latLngEdited && (
                  <Row>
                    <Col md={9} mdOffset={3}>
                      <Alert bsStyle="info">
                        <p>
                          Address edited - will geocode new coordinates on
                          submit.
                        </p>
                      </Alert>
                    </Col>
                  </Row>
                )}
              {this.state.activeUser?.isAdmin &&
                this.props.homeId && ( // is admin and not a new home
                  <Row layout="horizontal">
                    <Col md={8} mdOffset={2}>
                      <Input
                        {...SharedProps}
                        name="Coordinates"
                        id="coordinates"
                        value={home.lat ? `${home.lat}, ${home.lng}` : null}
                        label="Coordinates"
                        labelClassName="control-label"
                        type="text"
                        maxLength="30"
                        onChange={() =>
                          this.props.dispatch(HomeActions.latLngEdited())
                        }
                      />
                    </Col>
                    <Col md={2}>{getGoogleMapsButtonForHome(home)}</Col>
                  </Row>
                )}
              {this.props.latLngEdited && (
                <div>
                  <Col md={9} mdOffset={3}>
                    <Alert bsStyle="info">
                      <p>Coordinates edited - won't geocode on server.</p>
                    </Alert>
                  </Col>
                </div>
              )}
              <Input
                {...SharedProps}
                name={homeSitePhone}
                id="phone"
                value={home[homeSitePhone]}
                label="Phone"
                labelClassName="control-label"
                className="form-control"
                type="text"
                maxLength="12"
              />
              <Input
                {...SharedProps}
                name={homeSiteEmail}
                id="email"
                value={home[homeSiteEmail]}
                label="E-mail"
                labelClassName="control-label"
                className="form-control"
                type="text"
                maxLength="50"
              />
              <Input
                {...SharedProps}
                name={homeOwnerFN}
                id="ownerFirstName"
                value={home[homeOwnerFN]}
                label="Owner First Name"
                labelClassName="control-label"
                className="form-control"
                type="text"
                maxLength="15"
              />
              <Input
                {...SharedProps}
                name={homeOwnerLN}
                id="ownerLastName"
                value={home[homeOwnerLN]}
                label="Owner Last Name"
                labelClassName="control-label"
                className="form-control"
                type="text"
                maxLength="30"
              />
              {this.state.activeUser?.isAdmin &&
                this.props?.homeId && ( // is admin and not a new home
                  <div>
                    <Select
                      {...SharedProps}
                      name={homeElectricUtilityID}
                      label="Electric Utility"
                      labelClassName="control-label"
                      className="form-control"
                      options={this.getSelectItemListFromRecords(utilities)}
                      value={home[homeElectricUtilityID]}
                      // onChange={this.changeProp}
                      changeCallback={(homeElectricUtilityID, value) =>
                        this.changeProp(homeElectricUtilityID, value)
                      }
                    />
                    <Select
                      {...SharedProps}
                      name={homeGasUtilityID}
                      label="Gas Utility"
                      labelClassName="control-label"
                      className="form-control"
                      options={this.getSelectItemListFromRecords(utilities)}
                      value={home[homeGasUtilityID]}
                      // onChange={this.changeProp}
                      changeCallback={(homeGasUtilityID, value) =>
                        this.changeProp(homeGasUtilityID, value)
                      }
                    />
                  </div>
                )}
            </fieldset>
            <Row layout="horizontal" />
            {this.state.showAlert ? (
              <Alert bsStyle="danger">
                <h4>{this.state.errorMessages}</h4>
              </Alert>
            ) : null}
            {/* // Show different button layouts depending on whether the user is
            making a new home or editing */}
            {/* <Row layout="horizontal"> */}
            <div className="form-group row">
              <label className="control-label col-sm-3"></label>
              <div className="col-sm-9">
                {!this.state.isNewHome ? (
                  <input
                    className="btn btn-primary"
                    formNoValidate={true}
                    onClick={this.deleteHome}
                    type="button"
                    defaultValue="Delete"
                  />
                ) : null}{" "}
                {!this.state.isNewHome ? (
                  <input
                    className="btn btn-primary"
                    formNoValidate={true}
                    onClick={this.reprintCert}
                    type="button"
                    defaultValue="Re-print Cert"
                  />
                ) : null}{" "}
                {this.state.isNewHome ? (
                  <input
                    type="button"
                    className="btn btn-primary"
                    formNoValidate={true}
                    onClick={this.cancelAdd}
                    defaultValue="Cancel"
                  />
                ) : null}{" "}
                <input
                  type="submit"
                  className="btn btn-primary"
                  formNoValidate={true}
                  defaultValue="Submit"
                  disabled={this.state.submitting}
                />
              </div>
            </div>
            {/* </Row> */}
          </Formsy>
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  manufacturerId: PropTypes.string,
  homeId: PropTypes.string,
  pageTitle: PropTypes.string,
};

Home.defaultProps = { defaultTitle: "HOME CERTIFICATION APPLICATION" };

export default connect(mapStateToProps)(Home);
